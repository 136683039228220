import React from 'react';

const TermsOfService = () => {
  return (
    <div className="max-w-4xl mx-auto p-8 bg-white shadow-lg rounded-lg mt-8">
      <h1 className="text-4xl font-bold mb-6">Terms of Use for Nanosaasify</h1>
      <p className="text-gray-600 mb-2">Effective Date: 28/06/2024</p>
      
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">1. Introduction</h2>
        <p className="text-gray-700 mb-4">
          Welcome to Nanosaasify! These Terms of Use govern your use of our website and products, including LauncherBlast. By accessing or using our services, you agree to comply with and be bound by these terms.
        </p>
      </section>
      
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">2. Acceptance of Terms</h2>
        <p className="text-gray-700">
          By accessing or using any part of Nanosaasify's services, you accept all of the terms and conditions contained herein and all other operating rules, policies, and procedures that may be published from time to time on our website.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">3. Description of Services</h2>
        <p className="text-gray-700">
          Nanosaasify provides software-as-a-service (SaaS) products, including LauncherBlast, which helps you manage your business's social media communication in one platform.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">4. Use of Services</h2>
        <ul className="list-disc list-inside text-gray-700 mt-2">
          <li><strong>Eligibility:</strong> You must be at least 18 years old to use our services. By using our services, you represent and warrant that you meet this requirement.</li>
          <li><strong>Account Registration:</strong> You may be required to register an account to access certain features of our services. You agree to provide accurate and complete information and to update this information as necessary.</li>
          <li><strong>Account Security:</strong> You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">5. User Conduct</h2>
        <p className="text-gray-700 mb-4">
          You agree not to engage in any of the following prohibited activities:
        </p>
        <ul className="list-disc list-inside text-gray-700 mt-2">
          <li>Using our services for any illegal purpose or in violation of any local, state, national, or international law</li>
          <li>Attempting to interfere with, compromise the system integrity or security, or decipher any transmissions to or from the servers running our services</li>
          <li>Taking any action that imposes, or may impose at our sole discretion, an unreasonable or disproportionately large load on our infrastructure</li>
          <li>Uploading invalid data, viruses, worms, or other software agents through our services</li>
          <li>Using automated bots or other software to scrape or crawl our services without our express written permission</li>
          <li>Impersonating another person or otherwise misrepresenting your affiliation with a person or entity, conducting fraud, hiding or attempting to hide your identity</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">6. Intellectual Property</h2>
        <ul className="list-disc list-inside text-gray-700 mt-2">
          <li><strong>Ownership:</strong> All content and materials available on our services, including but not limited to software, text, graphics, logos, and images, are the property of Nanosaasify or its licensors and are protected by copyright, trademark, and other intellectual property laws.</li>
          <li><strong>License:</strong> We grant you a limited, non-exclusive, non-transferable, and revocable license to access and use our services for personal and business purposes in accordance with these terms.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">7. Fees and Payment</h2>
        <ul className="list-disc list-inside text-gray-700 mt-2">
          <li><strong>Pricing:</strong> Some of our services may be offered for a fee. The pricing and payment terms will be specified at the time of purchase.</li>
          <li><strong>Billing:</strong> You agree to pay all fees and charges incurred in connection with your use of our services, including any applicable taxes.</li>
          <li><strong>Refunds:</strong> Except as required by law, all fees are non-refundable.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">8. Termination</h2>
        <ul className="list-disc list-inside text-gray-700 mt-2">
          <li><strong>By You:</strong> You may terminate your account at any time by following the instructions on our website. Upon termination, you must cease all use of our services.</li>
          <li><strong>By Us:</strong> We may terminate or suspend your access to our services at any time, without prior notice or liability, for any reason, including if you breach these terms.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">9. Disclaimers and Limitation of Liability</h2>
        <ul className="list-disc list-inside text-gray-700 mt-2">
          <li><strong>Disclaimer of Warranties:</strong> Our services are provided "as is" and "as available" without warranties of any kind, either express or implied, including but not limited to implied warranties of merchantability, fitness for a particular purpose, or non-infringement.</li>
          <li><strong>Limitation of Liability:</strong> In no event shall Nanosaasify, its directors, employees, or agents be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your use or inability to use our services; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein; (iii) any interruption or cessation of transmission to or from our services; (iv) any bugs, viruses, trojan horses, or the like that may be transmitted to or through our services by any third party; or (v) any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through our services.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">10. Governing Law</h2>
        <p className="text-gray-700">
          These terms shall be governed and construed in accordance with the laws of France, without regard to its conflict of law provisions.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">11. Changes to Terms</h2>
        <p className="text-gray-700">
          We reserve the right, at our sole discretion, to modify or replace these terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">12. Contact Us</h2>
        <p className="text-gray-700">
          If you have any questions about these terms, please contact us at <a href="mailto:contact@cybedefend.com" className="text-blue-600 hover:underline">contact@cybedefend.com</a>.
        </p>
      </section>
    </div>
  );
};

export default TermsOfService;
