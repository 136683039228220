import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="max-w-4xl mx-auto p-8 bg-white shadow-lg rounded-lg mt-8">
      <h1 className="text-4xl font-bold mb-6">Privacy Policy for Nanosaasify</h1>
      <p className="text-gray-600 mb-2">Effective Date: 28/06/2024</p>
      
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">1. Introduction</h2>
        <p className="text-gray-700 mb-4">
          Welcome to Nanosaasify! We are committed to protecting your personal information and your right to privacy. This Privacy Policy describes our practices regarding the collection, use, and sharing of your personal information when you use our products, including LauncherBlast.
        </p>
        <p className="text-gray-700">
          By using our products, you agree to the terms of this Privacy Policy.
        </p>
      </section>
      
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">2. Business Information</h2>
        <p className="text-gray-700 mb-4">
          Business Name: Nanosaasify
        </p>
        <p className="text-gray-700">
          Contact Information: For any privacy-related concerns, you can contact us at <a href="mailto:contact@cybedefend.com" className="text-blue-600 hover:underline">contact@cybedefend.com</a>.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">3. Products Covered</h2>
        <p className="text-gray-700">
          This privacy policy applies to all products created by Nanosaasify, including but not limited to LauncherBlast. Future products will also be governed by this policy, and we will provide specific sections to address any unique aspects of those products as they are launched.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">4. Data Collection</h2>
        <p className="text-gray-700">
          We collect the following types of personal data from users:
        </p>
        <ul className="list-disc list-inside text-gray-700 mt-2">
          <li>Name</li>
          <li>Email address</li>
          <li>Social media account information via OAuth (with user consent)</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">5. Purpose of Data Collection</h2>
        <p className="text-gray-700">
          We collect data for the following purposes:
        </p>
        <ul className="list-disc list-inside text-gray-700 mt-2">
          <li>To provide and maintain our services</li>
          <li>To improve user experience</li>
          <li>For marketing and promotional purposes</li>
          <li>To process transactions and manage billing</li>
          <li>To communicate with users regarding updates, offers, and support</li>
          <li>To analyze site usage and improve the website's performance</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">6. Data Sharing</h2>
        <p className="text-gray-700">
          We share data with the following third parties:
        </p>
        <ul className="list-disc list-inside text-gray-700 mt-2">
          <li>Payment Processors: Stripe to handle transactions securely</li>
          <li>OAuth Providers: Google and GitHub to facilitate social media connections</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">7. User Rights</h2>
        <p className="text-gray-700">
          Users have the following rights regarding their data:
        </p>
        <ul className="list-disc list-inside text-gray-700 mt-2">
          <li>The right to access their personal data</li>
          <li>The right to correct inaccurate or incomplete data</li>
          <li>The right to request deletion of their data</li>
          <li>The right to object to or restrict the processing of their data</li>
          <li>The right to data portability</li>
          <li>The right to withdraw consent at any time</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">8. Data Security</h2>
        <p className="text-gray-700">
          We implement various security measures to protect user data, including:
        </p>
        <ul className="list-disc list-inside text-gray-700 mt-2">
          <li>Encryption of sensitive data</li>
          <li>Secure socket layer (SSL) technology</li>
          <li>Regular security audits and assessments</li>
          <li>Access controls and authentication mechanisms</li>
          <li>Data anonymization and pseudonymization where appropriate</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">9. Cookies and Tracking</h2>
        <p className="text-gray-700">
          We do not use cookies or other tracking technologies.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">10. Third-Party Links</h2>
        <p className="text-gray-700">
          Our website and products may include links to third-party sites. We do not control the privacy practices of these third-party sites, and users are encouraged to review their privacy policies.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">11. Children's Privacy</h2>
        <p className="text-gray-700">
          We do not knowingly collect data from children under the age of 13. If we become aware that we have inadvertently collected such data, we will take steps to delete it as soon as possible.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">12. International Users</h2>
        <p className="text-gray-700">
          We comply with international data protection regulations, including the General Data Protection Regulation (GDPR) and the California Consumer Privacy Act (CCPA).
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">13. Policy Updates</h2>
        <p className="text-gray-700">
          We may update this privacy policy from time to time. Users will be informed of any changes through:
        </p>
        <ul className="list-disc list-inside text-gray-700 mt-2">
          <li>Notifications on our website</li>
          <li>Email notifications to registered users</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">14. Additional Information for Specific Products</h2>
        <p className="text-gray-700">
          For each product, including LauncherBlast, we provide additional privacy information specific to that product:
        </p>
        <h3 className="text-xl font-semibold mt-4">LauncherBlast</h3>
        <ul className="list-disc list-inside text-gray-700 mt-2">
          <li><strong>Purpose:</strong> LauncherBlast helps you manage your business's social media communication in one platform.</li>
          <li><strong>Data Collection:</strong> OAuth access to social media accounts is used to manage social media activities with user consent. Users can delete this access at any time.</li>
          <li><strong>Third-Party Connections:</strong> LauncherBlast connects to social media platforms such as Twitter, Facebook, LinkedIn, etc. Users should review the privacy policies of these platforms for more information.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">15. Contact Us</h2>
        <p className="text-gray-700">
          If you have any questions or concerns about our privacy practices, please contact us at <a href="mailto:contact@cybedefend.com" className="text-blue-600 hover:underline">contact@cybedefend.com</a>.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
