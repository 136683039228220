import React from 'react';
import { Link } from 'react-router-dom';

const footerNavigation = {
  product: [
    { name: 'Cybedefend', href: "https://cybedefend.com/" },
    { name: 'Nexus-games', href: "https://nexus-games.com/" },
    { name: 'Scougal Rubber', href: "https://www.scougalrubber.com/" },
  ],
  company: [
    { name: 'Privacy Policy', href: '/privacy-policy' },
    { name: 'Terms of Service', href: '/tos' },
  ],
};

const Footer = () => {
  return (
    <div className="dark:bg-boxdark-2">
      <div className="mx-auto max-w-7xl px-6 lg:px-8 py-12 md:py-24">
        <footer aria-labelledby="footer-heading">
          <h2 id="footer-heading" className="sr-only">Footer</h2>
          <div className="flex flex-wrap justify-between gap-10">
            <div className="flex-1 mb-10">
              <img src="logo.png" alt="Logo" className="h-10 w-auto" />
              <p className="mt-4 max-w-xs text-sm">
                I launch SaaS products to help you manage your business. Let's get in touch and build something great together.
              </p>
              <p className="mt-4 text-sm">Copyright © {new Date().getFullYear()} - All rights reserved</p>
            </div>
            <div className="flex-1 flex flex-wrap justify-between gap-10">
              <div>
                <h3 className="text-sm font-semibold leading-6">LINKS</h3>
                <ul className="mt-6 space-y-4">
                  {footerNavigation.product.map((item) => (
                    <li key={item.name}>
                      <a href={item.href} className="text-sm leading-6 hover:underline">
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <h3 className="text-sm font-semibold leading-6">LEGAL</h3>
                <ul className="mt-6 space-y-4">
                  {footerNavigation.company.map((item) => (
                    <li key={item.name}>
                      <Link to={item.href} className="text-sm leading-6 hover:underline">
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="mt-10 flex items-center gap-x-4">
            <img src="julien_photo.jpg" alt="Julien Zammit" className="h-10 w-10 rounded-full" />
            <p className="text-sm leading-6">
              Hey there 👋 I'm <a href="https://www.linkedin.com/in/julienzammit/" className="font-semibold hover:underline">Julien</a>, you can follow my work on <a href="https://www.linkedin.com/in/julienzammit/" className="font-semibold hover:underline">Linkedin</a>
            </p>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Footer;
