import React from "react";
import { Route, Routes } from "react-router-dom";
import Profile from "./components/Profile";
import SaaSList from "./components/SaaSList";
import WebsiteList from "./components/WebsiteList";
import Meteors from "./components/ui/Meteors";
import Footer from "./components/Footer";
import PrivacyPolicy from "./private-policy/page";
import TermsOfService from "./tos/page";

function App() {
  return (
    <main className="isolate">
      <Routes>
        <Route
          path="/"
          element={
            <div>
              <div className="bg-[#e6e6e6] min-h-screen flex flex-col md:flex-row">
                <div className="relative h-auto md:h-screen flex items-start md:items-start justify-center p-8 md:p-0 md:ml-24 md:justify-start">
                  <Profile />
                  <div className="absolute inset-0">
                    <Meteors number={30} />
                  </div>
                </div>
                <div className="flex-1 overflow-y-auto p-8 md:p-8">
                  <div className="mb-10">
                    <h2 className="text-3xl font-bold mb-8">
                      My main project
                    </h2>
                    <SaaSList />
                  </div>
                  {/* <div className="mb-10">
                    <h2 className="mt-15 text-3xl font-bold mb-8">
                      My side projects
                    </h2>
                    <AgencyList />
                  </div> */}
                  <div className="mb-10">
                    <h2 className="mt-15 text-3xl font-bold mb-8">
                      My web development
                    </h2>
                    <WebsiteList />
                  </div>
                </div>
              </div>
              <div className="mx-auto mt-6 max-w-7xl px-6 lg:px-8 ">
                <Footer />
              </div>
            </div>
          }
        />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/tos" element={<TermsOfService />} />
      </Routes>
    </main>
  );
}

export default App;
